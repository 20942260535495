<template>
	<div class="wpRestApiAcfOptionsList">
		<strong>results for "{{endPoint}}" </strong> – 
		<a :href="getUrl" target="_blank">Link</a>
		<!--
		<pre>{{$props}}</pre>
		-->
		<pre>{{results}}</pre>
	</div>
</template>

<script>
	export default {
		name: 'WpRestApiAcfOptionsList',
		props: {
			restApiUrl: String,
			nameSpace: String,
			endPoint: String,
		},
		data() {
			return {
				results: [],
				getUrl: this.restApiUrl + '/' + this.nameSpace + '/' + this.endPoint
			}
		},
		methods: {
		},
		mounted() {			
			const _this = this 
			
			this.axios
				.get(_this.getUrl)
				.then(response => {
					this.results = response.data
				})
				.catch(function (error) {
					console.log(error);
				})
				.then(function () {
					// always executed
				});	

		},
	}
</script>

<style lang="less" scoped>
	.wpRestApiAcfOptionsList {
		padding: 0.5em;
		background-color: fade(white, 50);
		outline: 1px solid fade(black, 25);
	}
</style>

<template>
	<div class="wpRestApiPostsList">
		
		<div class="wpRestApiPostsList__header">
			<div><strong>{{endPoint}} ({{pagination.totalPosts}})</strong> – <a :href="getUrl2()" target="_blank">Link</a></div>
			<div class="wpRestApiPostsList__search">				
				<input 
					class="wpRestApiPostsList__searchInput" 
					type="text" 
					placeholder="search ..." 
					v-model.trim="searchString" />
				<a class="wpRestApiPostsList__searchCancel" @click="searchString = ''">x</a>
			</div>
		</div>
		
		<div class="wpRestApiPostsList__body">
			<ul class="wpRestApiPostsList__ul">			
				<li class="wpRestApiPostsList__li" v-for="post in posts" :key="post.id">
					<a target="_blank" :href="post.link">{{post.title.rendered}}</a>
				</li>
				<div class="wpRestApiPostsList__noResults" v-if="!posts.length">
					No results.
				</div>
			</ul>
		</div>
		
		<div class="wpRestApiPostsList__footer">
			<a	class="wpRestApiPostsList__paginationLink" 
				:class="{'wpRestApiPostsList__paginationLink--isActive' : pagination.currentPage == pageNumber}" 
				v-for="pageNumber in pagination.totalPages" 
				@click="pagination.currentPage = pageNumber" 
				:key="pageNumber">
				{{pageNumber}}
			</a>
			<br />
		</div>
		
	</div>
</template>

<script>
	export default {
		name: 'WpRestApiPostsList',
		props: {
			restApiUrl: String,
			nameSpace: String,
			endPoint: String,
			perPage: Number,
		},
		data() {
			return {
				posts: [],
				pagination: {
					currentPage: 1,
					totalPosts: undefined,
					totalPages: undefined,
				},
				searchString : '',
				XXXgetUrl: this.restApiUrl + '/' + this.nameSpace + '/' + this.endPoint
			}
		},
		watch: {
			'pagination.currentPage': function (val) {
				if(val) this.getPosts()
			},
			'searchString': function (val) {
				this.getPosts()
			},
		},	
		methods: {
			getUrl2(){
				let urlString = 
					this.restApiUrl + '/' + 
					this.nameSpace + '/' + 
					this.endPoint + 
					'?per_page=' + this.perPage + 
					'&page=' + this.pagination.currentPage
				
				if(this.searchString){
					urlString = urlString + '&search=' + this.searchString
				}
				
				return urlString
			},
			getPosts() {
				let getPostsUrl = this.getUrl2()
				
				this.axios
					.get(getPostsUrl)
					.then(response => {
						this.pagination.totalPosts = parseInt(response.headers['x-wp-total'])
						this.pagination.totalPages = parseInt(response.headers['x-wp-totalpages'])
						//this.pagination.currentPage = pageNumber
						this.posts = response.data
						
						if(this.pagination.totalPages > this.pagination.currentPage){
							this.pagination.currentPage = 1
						}
					})
					.catch(function (error) {
						console.log(error);
					})
					.then(function () {
						// always executed
					});				
			},
		},
		mounted() {			
			this.getPosts()
		},
	}
</script>

<style lang="less" scoped>
	a {
		text-decoration: none;
	}			
	a:hover {
		text-decoration: underline;
	}			
	a:before {
		content: "";
	}			
	
	.wpRestApiPostsList {
		background-color: fade(white, 50);
		outline: 1px solid fade(black, 25);
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}	
		.wpRestApiPostsList__header {
			padding: 0.5em;
			border-bottom: 1px solid fade(black, 25);
			flex-grow: 0;
			display: flex;
			justify-content: space-between;
			
			& > * {
				white-space: pre;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			& > *:last-child {
				flex-shrink: 0;				
			}
		}
			.wpRestApiPostsList__search {
				display: flex;
				align-items: center;
			}
			.wpRestApiPostsList__searchInput {
				transition: all 0.2s ease;
				border-radius: 0.7em;
				border: none;
				padding: 0 0.5em;
				width: 6em;
				height: 1.4em;
				&:focus { 
					width: 10em; 
					outline: none;
				}
			}
			.wpRestApiPostsList__searchCancel {
				position: relative;
				margin-left: -1.2em;
				display: inline-flex;
				//background-color: fade(black, 10);
				border: 1px solid fade(black, 20);
				width: 1em; height: 1em;
				text-align: center;
				border-radius: 50%;
				align-items: center;
				justify-content: center;
			}
		.wpRestApiPostsList__body {
			flex-grow: 1;
		}
			.wpRestApiPostsList__ul {
				padding: 0.5em;
				padding-left: 0em;
			}
				.wpRestApiPostsList__li {
					margin-left: 2em;					
				}
				.wpRestApiPostsList__noResults {
					padding: 0 0.5em;
					opacity: 0.65;
				}
		.wpRestApiPostsList__footer {
			flex-grow: 0;
			border-top: 1px solid fade(black, 25);
			padding: 0.5em;
		}
			.wpRestApiPostsList__paginationLink {
				display: inline-block;
				padding-right: 0.5em;
			}
			.wpRestApiPostsList__paginationLink--isActive {
				color: red;
			}
</style>

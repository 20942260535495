<template>
	<div class="wpRestApi debugOutline" data-debugName="wpRestApi">
		<!--
		<pre>{{$data}}</pre>
		-->
		<h2 class="wpRestApi__title">postTypes collections:</h2>
		<div class="wpRestApi__postLists">				
			<WpRestApiPostsList 
				v-for="endPoint in postTypes.endPoints" :key="endPoint"
				:restApiUrl="restApiUrl" 
				:nameSpace="postTypes.nameSpace"
				:perPage="postTypes.perPage"
				:endPoint="endPoint"
			></WpRestApiPostsList>
		</div>
		<h2 class="wpRestApi__title">acfOptions:</h2>
		<WpRestApiAcfOptionsList
			:restApiUrl="restApiUrl" 
			:nameSpace="acfOptions.nameSpace"
			:endPoint="acfOptions.endPoint"		
		></WpRestApiAcfOptionsList>
	</div>
</template>

<script>
	import appConfig from '@/../appConfig.js'
	import WpRestApiPostsList from '@/components/WpRestApi/WpRestApiPostsList.vue'
	import WpRestApiAcfOptionsList from '@/components/WpRestApi/WpRestApiAcfOptionsList.vue'
	
	export default {
		name: 'WpRestApi',
		components: {
			WpRestApiPostsList,
			WpRestApiAcfOptionsList,
		},			
		data() {
			return {
				restApiUrl        : null,
				postTypes : {
					nameSpace        : 'wp/v2', 
					perPage          : 5,
					excludeEndPoints : [
						'posts',
					],
					endPoints        : [],
				},
				acfOptions : {
					nameSpace     : 'acf/v3', 
					endPoint	  : 'options/options',
				},
			}
		},
		methods: {
			getRestApiUrlForEnv(){
				const currentHostname = location.hostname
				const appConfigEnvironments = appConfig.environments
				let currentEnvironment = null

				this._.forEach(appConfigEnvironments, function(value, key){
					if(value.hostname == currentHostname){
						currentEnvironment = key
					}					
				})
				
				if(currentEnvironment){
					return appConfigEnvironments[currentEnvironment].restApiUrl
				}
			}
		},
		created() {
			// Get restApi detected by current hostname
			this.restApiUrl = this.getRestApiUrlForEnv()
		},
		mounted() {		
			// Get the endpoints for all postTypes and populate fetchedEndPoints[]
			const getTypesUrl = this.restApiUrl + '/' + this.postTypes.nameSpace + '/types'						
			const excludeEndPoints = this.postTypes.excludeEndPoints
			
			this.axios
				.get(getTypesUrl)
				.then(response => {
					const responseData = response.data
					const _this = this
					this._.forEach( responseData, function(value, key){
						let restBase = value.rest_base
						if( excludeEndPoints.indexOf(restBase) == -1 ){
							_this.postTypes.endPoints.push( restBase )							
						}
					})
				})
				.catch(function (error) {
					console.log(error);
				})
				.then(function () {
				});	
		
		},
	}
</script>

<style lang="less" scoped>
	.wpRestApi {
		font-family: sans-serif;
		font-size: 13px;
		line-height: 1.25em;
		padding: 1em;
	}
	.wpRestApi__title {
		margin: 0;
		margin: 1em 0;
	}
	.wpRestApi__postLists {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		justify-content: space-between; /* 4 */		
		//grid-row-gap: 1vw;
		//grid-column-gap: 1vw;
		grid-gap: 1vw;
		
		//& > * {	margin-bottom: 3.25%; }
		
		@media only screen and (max-width: 1000px) {
			grid-template-columns: repeat(3, 1fr);
		}
		@media only screen and (max-width: 750px) {
			grid-template-columns: repeat(2, 1fr);
		}
		@media only screen and (max-width: 600px) {
			grid-template-columns: repeat(1, 1fr);
		}
		
	}
</style>

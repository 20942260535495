<template>
	<MhView :isLoading="isLoading">
		
		<h1>Wordpress RestApi Overview</h1>		
		<keep-alive>
			<WpRestApi></WpRestApi>
		</keep-alive>
		
	</MhView>
</template>

<script>
	// @ is an alias to /src
	import MhView from '@/components/MhView/MhView.vue'
	import WpRestApi from '@/components/WpRestApi/WpRestApi.vue'

	export default {
		name: 'restApiView',
		components: {
			MhView,
			WpRestApi,
		},		
		data() {
			return {	
				isLoading : true			
			}
		},
		methods: {
		},
		mounted() {
			this.isLoading = false
		},		
	}
</script>

<style lang="less" scoped>	
</style>
